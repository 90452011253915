module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"financiae","short_name":"financiae","start_url":"/","background_color":"#356caf","theme_color":"#356caf","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d767876f06adbe710eb34334b6ecc383"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Merriweather:400,300,300italic,400italic,700,700italic","Merriweather+Sans:400,700","Prompt:100,200,300,400,700","Open+Sans:100,200,300,400,500,600,700,800"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1027394021054463"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
